@import "_config.scss";


div.flatIndexPage {
    position: absolute;
    top: 56px;
    bottom: 0;
    left: 0;
    right: 0;
    background: $mainBackground;
}

div.flatIndexViewer {
    flex: 0 1 41%;
    background: #fff;
    display: flex;
    flex-flow: column;
    height: 100%;
    
}

div.flatIndexViewer div.breadcrumb {
    background: #fff;
    padding: 24px 15px 0 15px;
}

div.flatIndexViewer div.breadcrumb span {
    color: $docCounterColor;
}

div.flatIndexViewer div.breadcrumb span.bcSep {
    display: inline-block;
    margin-right: 15px;
    margin-left: 15px;
}


div.flatIndexViewer div.cardsContainer {
    flex: 1 1 auto;
    background: #fff;
}


div.flatIndexViewer div.indexCard {
    padding-top: 11px;
    padding-bottom: 11px;
    border-bottom: 1px solid $relevanceBarBG;
    margin-left: 0px;
    margin-right: 0px;
}

div.flatIndexViewer div.indexCard:hover {
    background: $transparentBtnHoverBG;
}

div.flatIndexViewer div.indexCard h4 {
    line-height: 30px;
}

div.flatIndexViewer div.indexCard h4.counter {
    color: $docCounterColor;
}

div.flatIndexViewer div.indexCard svg {
    height: 30px;
}



