@import "_config.scss";

svg.timeline {
  font: $fontSizeXS sans-serif;
}

svg.timeline path {
  fill: none;
  stroke: $linkStroke;
  stroke-opacity: 0.8;
  stroke-width: 3px;
}

.node circle {
  stroke: $nodeStroke;
  stroke-width: 1px;
}

div.tooltip {
  position: absolute;
  padding: 10px;
  background: $resultHoverBackground;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
  font-size: $fontSizeXS;
  width: 400px;
  opacity: 1;
}


div.BrowserApp {
    display: flex;
    flex-flow: column;
    position: absolute;
    bottom: 0;
    top: 52px;
    left: 0;
    right: 0;
    background: $mainBackground;
}

div.Browser {
    display: flex;
    flex: 1 1 auto;
    flex-flow: row;
    min-height: 0;
}

div.resultsBrowser {
    flex: 0 1 41%;
    background: #fff;
    display: flex;
    flex-flow: column;
}

div.resultsBrowser.expanded {
    flex: 0 1 100%;
}

div.resultsBrowser.expanded + div.docViewer {
    max-width: 0;
    opacity: 0;
}


div.timelineContainer {
    flex: 0 0 auto;
    overflow: show;
    display: flex;
    flex-flow: column;
}

div.resultsBrowser div.hint {
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
}

div.hint h4 {
  max-width: 400px;
  margin:auto;
}


div.cardsContainer {
    flex: 1 1 auto;
    
}

div.timelineCardsContainer {
    flex: 1 1 auto;
    
}


svg.timeline g.thumb > line + g {
    display: none;
}

svg.timeline g.thumb > line {
    cursor: pointer;

}

svg.timeline g.thumb > line:hover + g {
    display: block;
    overflow: hidden;
}

svg.timeline g.tick line {
    stroke-width: 1px;
    stroke: #cececece;
    stroke-dasharray: 4 1 2 3;
}



g.readIcon path {
    stroke-width: 1;
}

g.readIcon path#Combined-Shape-Copy-3 {
    fill: #8C96A7;
}


g.flags g.flag path {
    stroke-width: 0;
}

g.flags g.flag-green path {
    fill: #37833C;
}

g.flags g.flag-red path {
    fill: #C8382D;
}

g.flags g.flag-yellow path {
    fill: #FFCB34;
}



div.timelineContainer {
    max-height: calc(100% - 40px);
}

div.timelineContainer div.timelineVisual {
    max-height: 100%;
}
  

div.timelineContainer svg.timeline {
  max-height: 100%;
  max-width: 41vw;
}

div.resultsBrowser.expanded div.timelineContainer svg.timeline {
    max-width: 100vw;
    width: 100%;
}

div.resultsBrowser.expanded div.timelineContainer svg.timeline g.titleContainer {
    position: absolute;
    right: 0;
}


div.timelineContainer svg.timeline g,
div.timelineContainer svg.timeline path {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

div.timelineContainer svg.timeline g circle,
div.timelineContainer svg.timeline g text {
  cursor: pointer;
}


button.saveDocsBtn:hover,
button.listViewBtn:hover,
div.generalHelper:hover {
    background: $interfaceButtonBackgroundHover;
}


button.pnButton,
button.resultsViewTypeBtn {
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
}

button.pnButton:first-child,
button.resultsViewTypeBtn:first-child {
    border-radius: $stdBorderRadius 0 0 $stdBorderRadius;
}

button.pnButton:last-child,
button.resultsViewTypeBtn:last-child {
    border-radius: 0 $stdBorderRadius $stdBorderRadius 0;
}


div.closenessRelevance {
    margin-bottom: -50px;
}

#date-slider-container label {
    padding-left: 10px;
}
#cdate-slider-container label {
    padding-left: 10px;
}

label.groupVersionsBtn {
    background: #fff;
    padding: 4px 5px 4px 25px;
    border-radius: $stdBorderRadius;
    color: $transparentBtnColor;
    background: $transparentBtnAlternativeBG;
    border: $transparentBtnAlternativeBorder;
    font-size: $fontSizeM;
    
}

label.groupVersionsBtn span.checkmark {
    top: 4px;
    left: 5px;
}

rect.selectedRangeRectangle {
    fill: #dcdcdc55;
}

div.BrowserApp div.subheader div.optionsMenu div.sortByMenu button {
    padding: 4px 10px;
}


div.BrowserApp div.subheader  button.gvButton{
    font-size: $fontSizeM;
    padding: 2px 5px;
    border: none;
    background: none;
}

div.BrowserApp div#cdate-slider-container input.input,
div.BrowserApp div#date-slider-container input.input {
    width: 160px;
} 

div.BrowserApp div#cdate-slider-container div.filter,
div.BrowserApp div#date-slider-container div.filter {
    display: inline-block;
    margin-bottom: 10px;
} 

div.BrowserApp div#cdate-slider-container div.filter + div.filter,
div.BrowserApp div#date-slider-container div.filter + div.filter {
    margin-left: 20px;
}

div.BrowserApp div.listNavTop,
div.BrowserApp div.listNavBottom {
    text-align: center;
}

div.BrowserApp div.listNavBottom button {
    margin-left: auto;
    margin-right: auto;
}

div.BrowserApp div.listNavTop span.position {
    display: block;
    font-size: $fontSizeL;
    margin: 5px auto;
    color: #a8b4c7;
    width: 100%;
}

div.BrowserApp div.listNavTop button:hover,
div.BrowserApp div.listNavBottom button:hover {
    color: #fff;
    border-color: #fff;
}

div.BrowserApp div.gvOptionsMenu {
    width: 160px;
}




div.timelineCard,
div.timelineCard span.projectTitle {
    white-space: nowrap;
}

div.timelineCard.opened,
div.timelineCard:hover {
    background: #e9ebef;
}

div.timelineCard span.projectTitle span.caption {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 300px;
    display: inline-block;
    line-height: 24px;
    vertical-align: middle;
    cursor: pointer;
}

div.timelineCard.opened span.projectTitle span.caption {
    font-weight: bold;
}


div.timelineCard div.lineContainer {
    display: inline-block;
    height: 24px;
    vertical-align: middle;
    position: relative;
    margin-right: 10px;
}

div.timelineCard div.lineContainer div.timelineTick {
    width: 2px;
    height: 16px;
    top: 4px;
    position: absolute;
    cursor: pointer;
    z-index: 110;
}

div.timelineCard div.lineContainer div.timelineTick.ticks-0,
div.timelineCard div.lineContainer div.timelineTick.ticks-1 {
    height: 10px;
    top: 7px;
}


div.timelineCard div.lineContainer div.line {
    height: 2px;
    top: 11px;
    position: absolute
}

div.timelineCardsContainer > div.ScrollbarsCustom {
    height: calc(100% - 28px - 41px) !important;
}

div.timelineCardsContainer > div.ScrollbarsCustom  div.ScrollbarsCustom-Content{
    position: absolute;
}


div.timelineCardsContainer div.controlsBar {
    background: #becad6;
}

div.timelineCardsContainer div.controlsBar span.position {
    line-height: 28px;
    margin-left: 10px;
    
}

div.timelineCardsContainer div.controlsBar span.buttons {
    float: right;
}

div.timelineCardsContainer div.controlsBar span.buttons button {
    line-height: 18px;
    height: 18px;
    width: 24px;
    padding: 0;
    background: #edf2f5;
    color: #494e5f;
}

div.timelineCardsContainer div.controlsBar span.buttons button.disabled {
    opacity: 0.5;
    cursor: default;
}

div.resultsBrowser.expanded div.timelineCard span.projectTitle {
    position: sticky;
    left: calc(100% - 350px);
    width: 350px;

}


div#contentstoprint div.insideScrollBar,
div.timelineCardsContainer div.insideScrollBar {
    padding-bottom: 120px;
    margin-top: -65px;
    position: relative;
    padding-left: 10px;
}

div#contentstoprint div.timelineLegend,
div.timelineCardsContainer div.timelineLegend {
    position: sticky;
    width: 100%;
    background: #f2f5f7;
    z-index: 200;
}

div#contentstoprint div.guidesCaptions,
div.timelineCardsContainer div.guidesCaptions {
    position: absolute;
    width: 100%;
}

div#contentstoprint div.guidesCaptions > div,
div.timelineCardsContainer div.guidesCaptions > div {
    position: absolute;
}

div.guideCaption.guideCursor {
    background: #00a5ff;
    color: #fff;
    padding: 2px 10px;
    z-index: 100;
}

div#contentstoprint div.guide.guideCursor ,
div.timelineCardsContainer div.guide.guideCursor {
    border-left: 1px solid #00a5ff;
    z-index: 10;
}

div#contentstoprint div.guide,
div.timelineCardsContainer div.guide {
    position: absolute;
    width: 0px;
    min-height: 100%;
    z-index: 0;
    border-left: 1px dashed #becad6;
    opacity: 0.6;
}

div#contentstoprint div.outZone,
div.timelineCardsContainer div.outZone {
    position: absolute;
    min-height: 100%;
    z-index: 0;
    border-left: 1px solid #becad6;
    opacity: 0.6;
    background: #e4e5e6;
}

div#contentstoprint div.outZoneRight,
div.timelineCardsContainer div.outZoneRight {
    right: 0;
}

div#contentstoprint div.outZoneLeft,
div.timelineCardsContainer div.outZoneLeft {
    left: 0;
}


div.timelineActiveSliderContainer {
    position: relative;
    top: 36px !important;
}

div.timelineActiveRangeVisual {
    margin-top: 10px;
}

div.timelineActiveRangeVisual input {
    padding: 3px;
    font-size: 10px;
    width: 60px;
    text-align: center;
    position: absolute;
}

div.timelineActiveRangeVisual input.timelineActiveMax {
    right: 0;
}

div.timelineActiveRangeVisual input.timelineActiveMin {
    left: 0;
}


div.timelineCardsContainer div.topBar {
    padding-left: 10px;
    background: #F1F3F6;
    border: 1px solid #C0C9D6;
    height: 41px;
}

div.timelineCardsContainer div.topBar button {
    background: $transparentBtnAlternativeBG;
    border: $transparentBtnAlternativeBorder;
    color: $transparentBtnColor;
    outline: none;
    padding: 2px 5px;
}

div.timelineCardsContainer div.topBar button:hover {
    color: $transparentBtnHoverColor;
    background: $transparentBtnHoverBG;
    
}

div.scaleControlButtons {
    display: inline-block;
}

div.scaleControlButtons > button {
    border-radius: 13px;
    width: 26px;
    height: 26px;
    display: inline-block;
    padding: 0;
    outline: none;
}

div.scaleControlButtons > button.disabled {
    cursor: default;
    opacity: 0.7;
}

div.globalTimelineTick {
    position: absolute;
    width: 2px;
    cursor: pointer;
}


div.timelineCardsContainer.overview div.timelineLegend {
    top: 100px;
    
}

div.timelineCardsContainer.overview div.guide{
    height: 100px;
    min-height: auto;

}

div.timelineCardsContainer div.timelineLegend div.gTimeline {
    z-index: 1000;
    position: absolute;
    width: 100%;
}


div.timelineCardsContainer.overview span.position input {
    padding: 0;
    font-size: 12px;
    text-align: center;
    width: 50px;
}

div.timelineTickExtract {
    padding-bottom: 5px;
    border-bottom: 1px dashed #d1d1d1;
    cursor: pointer;
}

div.tickPopover {
    transform: none !important;
}

div.tickPopoverContainer {
    position: fixed ;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: $fadedBackground;
    padding: 10%;
    overflow-y: auto;
}

div.tickPopoverContainer span.theDate {
    background: #ddd;
}


div.tickPopoverContainer > div.contents {
    background: #fff;
}

div.timelineLegend {
    min-height: 80px;
}

button.sortByBtn {
    max-width: 135px;
    overflow: hidden;
}