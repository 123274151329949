@import "_config.scss";

div.watchesBrowser {
    height: 100%;
    background: #F2F3F6;
}


div.watchQuery span.selectionPanel {
    padding: 8px 8px 8px 12px;
    display: inline-block;
    background: $selectionCardBG;
    color: $selectionCardColor;
    border-radius: $stdBorderRadius;
    margin-bottom: 10px;
    margin-right: 10px;
    font-size: $fontSizeXL;
}

div.watchQuery span.selectionPanel.panelMI {
    background: $selectionCardMIBG;
    color: $selectionCardMIColor;
}

div.watchQuery span.selectionPanel.panelME {
    background: $selectionCardMIBG;
    color: $selectionCardMIColor;
}


div.watchesBrowser div.watchQueries {
    padding:0;
}

div.watchesBrowser div.watchQueries div.queries > div > div.col-md-12 {
    padding: 10px;
}

div.watchesBrowser div.watchQueries div.queries > div > div.col-md-12 + div.col-md-12 {
    border-top: 1px solid #d8d8d8;
}

div.watchesBrowser div.watchQuery div.omContainer {
    position: absolute;
    right: 8px;
    top: 8px;
}

div.watchesBrowser div.watchQuery div.omContainer > button > svg {
    transform: rotate(270deg);
    margin: 0;
    width: 15px;
}

div.watchesBrowser div.watchQuery div.omContainer > button {
    padding: 2px;
    background: none;
    border: none;
}

div.watchesBrowser div.watchQuery {
    border-radius: $stdBorderRadius;
    width: 100%;
    min-height: 81px;
    padding: 8px 13px;
    resize: none;
    border: 1px solid #fff;
    color: $queryTextAreaColor;
    font-size: $fontSizeXL;
    cursor: pointer;
    background: #fff;
}

div.watchesBrowser div.watchQuery:hover {
    border: $queryTextAreaBorder;
}

div.watchesBrowser div.watchQuery p {
    margin-bottom: 5px;
}

div.watchesBrowser div.watchQuery p.created {
    font-size: $fontSizeM;
    color: #aaaaaa;
    margin-bottom: 0;
}
