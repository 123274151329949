@import "_config.scss";

div.ActivityBrowser {
    background:$mainBackground;
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 32000;
}

div.ActivityBrowser div.fullSize {
    top: 90px;
}

div.ActivityBrowser div.fullSize div.container-fluid > div.panel {
    background: $panelBG;
    border-radius: $stdBorderRadius;
    padding: 20px 60px;
    border: 1px solid $hSeparatorColor;
}

div.ActivityBrowser div.fullSize span.entriesCounter {
    color: $docCardFieldValueColor;
}

div.ActivityBrowser div.fullSize div.hSep {
    height: 1px;
    background: $hSeparatorColor;
    display: block;
    width: calc(100% + 90px);
    margin-left: -45px;
    margin-right: -45px;
    
}

div.ActivityBrowser button.showMoreBtn {
    width: 100%;
}

div.ActivityBrowser button.refreshBtn {
    display: none;
    width: auto;
    font-size: $fontSizeM;
}
    

div.ActivityBrowser div.fullSize div.logCard {
    padding-top: 10px;
}

div.ActivityBrowser div.fullSize div.logCard > div.col-md-10 {
    border-top: 1px solid $hSeparatorColor;
}

div.ActivityBrowser div.fullSize div.logCard div.hSep + div.col-md-2 + div.col-md-10 {
    border-top: none;
}

div.ActivityBrowser div.fullSize div.logCard > div.col-md-* {
    padding-top: 20px;
    padding-bottom: 20px;
}

div.ActivityBrowser div.fullSize div.logCard > div.col-md-2 {
    padding-top: 30px;
}

div.ActivityBrowser div.fullSize div.logCard > div.col-md-10 h4 {
    margin-top: 2px;
}

div.ActivityBrowser div.fullSize div.headerRow {
    margin-bottom: 0px;
}

div.ActivityBrowser div.fullSize div.logCard div.contents {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 16px;
    cursor: pointer;
}

div.ActivityBrowser div.fullSize div.logCard div.contents:hover {
    background: $linkLikeBtnActiveBG;
}

div.ActivityBrowser div.fullSize div.logCard div.contents p {
    color: $h4Color;
}
div.ActivityBrowser div.fullSize div.logCard div.contents span.timeSpan {
    color: $docCardFieldValueColor;
}

div.ActivityBrowser div.fullSize div.logCard div.contents span.activityUser {
    color: $docCardFieldValueColor;
    font-weight: 400;
}

div.ActivityBrowser span.selecitonPanel {
    display: inline-block;
    padding: 2px 5px;
    border-radius: $stdBorderRadius;
    font-size: $fontSizeL;
    
}

div.ActivityBrowser span.panelMI {
    background: $selectionCardMIBG;
    color: $selectionCardMIColor;
}

div.ActivityBrowser span.panelME {
    background: $selectionCardMIBG;
    color: $selectionCardMIColor;
}

div.ActivityBrowser span.panelImp {
    background: $selectionCardBG;
    color: $selectionCardColor;
}

