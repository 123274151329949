@import "_config.scss";

div.companyPanel {
    position: absolute;
    top: 56px;
    bottom: 0;
    left: 0;
    right: 0;
    background: $alternativeBackground;

}

div.companyPanel div.fullSize {
    top: 56px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

div.companyPanel div.userLine {
    background: $cellBackground;
    border-radius: $stdBorderRadius;  
    cursor: pointer;
    height: 100%;
    margin-left: 0;
    margin-right: 0;
    border: $cellBorder;
    padding: 16px 0;
    margin-bottom: 10px;
}

div.companyPanel div.userLine:hover {
    -webkit-box-shadow: $shadowSizes $shadowColor;
    -moz-box-shadow: $shadowSizes  $shadowColor;
    box-shadow: $shadowSizes  $shadowColor;
}

div.companyPanel div.userLine > div > span {
    padding-top: 6px;
    display: inline-block;
}

div.companyPanel div.userLine button {
    margin: 0;
    margin-left: 6px;
}

div.companyPanel input {
    padding-top: 6px;
    padding-bottom: 7px;
}


div.companyPanel form {
    margin-bottom: 40px;
}

div.sb-avatar {
    margin-right: 10px;
}
