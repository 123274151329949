$stdMarginLeft: 100px;
$stdMarginRight: 100px;
$stdMarginTop: 20px;

$transparentBtnBG: none;
$transparentBtnAlternativeBG: #ffffff;
$transparentBtnAlternativeBorder: 1px solid #d7dbe9;
$transparentBtnColor: #7d87a5;
$transparentBtnHoverBG: #EEF2F7;
$transparentBtnHoverColor: #8189a1;
$transparentBtnActiveBG: #d1e4fe;
$transparentBtnActiveColor: #26458a;
$transparentBtnActiveBorder: 1px solid #0d41b9;
$transparentBtnActiveThickBorder: 3px solid #0d41b9;


$borderAccentColor: #0d41b9;

$linkLikeBtnColor: #1e2b5c;
$linkLikeBtnActiveBG: #eef2f7;

$separatorColor: #C0C9D6;
$hSeparatorColor: #D9DEE6;
$panelBorderColor: #D9DEE6;

$optionsMenuBg: #ffffff;

$shadowColor: rgba(5,14,70,0.15);
$shadowSizes: 0px 4px 10px 0px;


$mainBackground: #c4cbd7;
$alternativeBackground: #F3F8FB;
$fadedBackground: url('../img/faded.png'); 


$headerBackground: #ffffff;
$subheaderBackground: #F1F3F6;
$headerBorder: none;
$subheaderBorder: 1px solid #C0C9D6;

$projectNavBG: #7a8eab;
$projectNavBorder: 1px solid #727d9b;
$projectNavBtnBG: none;
$projectNavBtnColor: #adb2c3;
$projectNavBtnHoverBG: rgba(255, 255, 255, 0.2);;
$projectNavBtnHoverColor: #adb2c3;
$projectNavBtnActiveBG: #ffffff;
$projectNavBtnActiveColor: #494e5f;

$popoverBtnBG: #F9FAFB;
$popoverBtnColor: #1e2b5c;
$popoverBtnHoverBG: #e4e7ee;
$popoverBtnHoverColor: #1e2b5c;


$hColor: #424242;
$h4Color: #595D6B;
$hLinkColor: #1e2b5c;
$fontColorGrey: #a8b4c7;


$almostTransparentTextColor:#AEB2C3; 
$semiTransparentTextColor:#63687B;
$greyTextColor: #878D9A;

$lightBtnBG: none;
$lightBtnColor: #a8b4c7;
$lightBtnBorder: 1px solid #a8b4c7;
$lightBtnHoverBG: none;
$lightBtnHoverColor: #546081;
$lightBtnHoverBorder: 1px solid #546081;

$tabBtnBG: none;
$tabBtnColor: #a8b4c7;
$tabBtnBorder: none;
$tabBtnHoverBG: none;
$tabBtnHoverColor: #546081;
$tabBtnHoverBorder: none;

$cellBackground: #F9FAFB;
$cellBorder: 1px solid #D2D9E5;

$filtersPanelBG: #cad4e2;



$dashedBorderColor: #9FA5AD;
$dashedBorderColorHover: #76808D;


$stdBorderRadius: 4px;

$redAccentColor: #ff5959;
$roseBackground: #ffffff;
$roseBackgroundHover: #FFE2D8;

$outerBtnBG: #E5F1FF;
$outerBtnColor: #2599FE;
$outerBtnHoverBG: #E5F1FF;
$outerBtnHoverColor: #2599FE;

$inputTintColor: #bbc6de;
$inputBackground: #ffffff;
$inputPlaceholderColor: #9BA1B7;

$mainBtnColor: #ffffff;
$mainBtnBg: #105dc5;
$mainBtnBorder: 1px solid #105dc5;
$mainBtnHoverColor: #ffffff;
$mainBtnHoverBg: #174e99;
$mainBtnHoverBorder: 1px solid #174e99;
$mainBtnDisabledColor: #a2aab3;
$mainBtnDisabledBg: #edf2f5;
$mainBtnDisabledBorder: 1px solid #bcc4c9;


$docCardTitleColor: #384057;
$docCardFieldValueColor: #6a728e;
$docCardDescription: #121213;
$docCardOpenBG: #e3e9f0;
$docCardHoverBG: #f4f6f9;
$relevanceBarBG: #dae3eb;

$docCounterColor: #9ba1b7;
$docCounterTotalColor: #c6cbde;

$panelBG: #ffffff;

$queryTextAreaBorder: 1px solid #cce3ff;
$queryTextAreaColor: #2e313a;
$queryFormLabelsColor: #0a2544;

$hintsBG: #e4efff;
$hintsColor: #5e96e7;

$selectionCardBG: #e8eef4;
$selectionCardColor: #5c7384;
$selectionCardHoverBG: #e8eef4;
$selectionCardHoverColor: #5c7384;

$selectionCardMIColor: #000000;
$selectionCardMIHoverColor: #000000;
$selectionCardMIBG: #FFeff1;
$selectionCardMIHoverBG: #FFeff1;
$selectionCardMIBorder: #ffffff;
$selectionCardMIHoverBorder: #ff7a7f;

$docMIBG: #FF8080;


$suggestionColor: #222b44;

$checkboxColor: #3f75f1;


$mainAccentColor: #1e2b5c;
$mainAccentHoverColor: #1e2b5c;
$inverseForAccentColor: #ffffff;


$emptyButtonBackground: #ffffff;
$emptyButtonBackgroundHover: #edf5f1;
$emptyButtonColor: #000000;




$cellSeparatorColor: #cccccc;
$cellColor: #2d2d2d;

$panelBackground: #ffffff;

$darkTextColor: #454545;
$superDarkTextColor: #393939;




$linkStroke: #999999;
$nodeStroke: #FF9900;
$resultHoverBackground: #ffffff;

$tooltipBackground: #000000;
$tooltipColor: #ffffff;

$questionMarkBackground: #6d6d6d;
$questionMarkColor: #ffffff;

$filtersPanelBackground: #ffffff;
$filtersPanelColor: #6f6f6f;
$filtersPanelHeaderColor: #505656;

$interfaceButtonColor: #000000;
$interfaceButtonBackground: #ffffff;
$interfaceButtonBackgroundHover: #fefefe;
$interfaceButtonColorActive: #000000;
$interfaceButtonBackgroundActive: #eeeeee;

$docViewerTopMenuBackground: #cccccc;
$docViewerTopMenuButtonBackground: #ffffff;
$docViewerTopMenuButtonColor: #000000;

$subColor: #66696c;

$queryFormHintBackground: #ffffcc;

$sliderRail: #d1e4fe;
$sliderHandleBackground: #26458a;
$sliderHandleColor: #333333;
$sliderTrack: #7d87a5;


$fontSizeXXS: 8px;
$fontSizeXS: 9px;
$fontSizeS: 10px;
$fontSizeM: 12px;
$fontSizeL: 14px;
$fontSizeXL: 16px;
$fontSizeXXL: 20px;
$fontSizeXXXL: 22px;

@import "../config/style.scss";
