@import "_config.scss";

div.queryForm {
    flex: 0 1 auto;
  background:#FFF;
  z-index: 500;
  max-height: 100%;
  position: relative;
}

div.queryForm.minimized {
    height: auto;
}

div.queryForm.minimized textarea {
    max-height: 38px;
}

div.queryForm button.submitBtn {
    position: absolute;
    z-index:10;
    margin:0;
    right: 3px;
    top:3px;
    height: calc(100% - 6px);
}

div.queryForm button.addToWatchBtn {
    position: absolute;
    right: -60px;
    top: 4px;
    margin: 0;
    border: none;
    background: none;
    color: $projectNavBtnColor;

}

div.queryForm button.clearBtn {
    position: absolute;
    right: 4px;
    margin: 0;
    padding: 2px 6px;
}
 

div.queryForm div.fullSize {
    top: 55px;
}

div.queryForm.minimized div.GHelper,
div.queryForm.minimized label,
div.queryForm.minimized button.clearBtn,
div.queryForm.minimized div.row.suggestionsPanel,
div.queryForm.minimized div.row.submitBtnsRow {
    display: none;
}

div.queryForm.minimized div.configRow > div {
    padding-top: 5px;
    padding-bottom: 5px;

}
div.queryForm div.configRow > div > div {
    display: inline-block;
}

div.queryForm div.configRow > div > div + div {
    padding-left: 15px;
}

div.queryForm.minimized div.stdTopPadded {
    padding-top: 0;
}

div.queryForm div.row {
    background: $panelBG;
}

div.queryForm div.row.queryContainer {
    background: #cce3ff;
    border-radius: $stdBorderRadius;
    
}
div.queryForm div.row.submitBtnsRow {
    border-radius: 0 0 $stdBorderRadius $stdBorderRadius;
    border-top: 2px solid #a3b2c1;
}

div.queryForm div.row > div {
    padding-bottom: 10px;
    padding-top: 10px;
}

div.queryForm div.row > div:last-child {
    border-bottom: none;
}

div.queryForm div.row.queryContainer > div {
    padding: 2px 2px;
}

div.queryForm div.row.queryContainer textarea {
    border-radius: $stdBorderRadius;
    margin: 0;
    width: 100%;
    padding: 8px 113px 8px 13px;
    resize: none;
    border: $queryTextAreaBorder;
    color: $queryTextAreaColor;
    background: #fff;
}

div.queryForm div.row.queryContainer textarea:focus {
    border: 1px solid #1e86ff;
}


div.queryForm div.helper {
    margin: 5px 10px;

    -webkit-transition: 1.0s;
    -moz-transition: 1.0s;
    -o-transition: 1.0s;
    transition: 1.0s;
    clear: both;
    color: $hintsColor;
    animation: shake 1.5s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    
}

div.translateCBcont {
    padding: 13px;
    border-top: 1px solid $hSeparatorColor;
}

div.queryForm div.translateCBcont label {
    font-size: $fontSizeM;
}

  
@keyframes shake {
    10%, 90%{
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80%{
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}


div.queryForm label {
    margin-bottom: 0px;
    display: block;
    font-weight: 500;
    color: $queryFormLabelsColor;
    font-size: $fontSizeL;
}

div.queryForm div.queryLang label,
div.queryForm div.filtersPanel label {
    display: inline-block;
}

div.mustPanel div.queries {
    border-right: 4px solid #c4cbd8;
    padding-right: 15px;
}


div.mustPanel input.ReactTags__tagInputField {
    padding: 3px 6px;
    width: 100px;
}

div.queryForm.minimized div.mustPanel input.ReactTags__tagInputField {
    padding: 2px 6px;
    font-size: $fontSizeM;
}

div.mustPanel div.ReactTags__tagInput {
    display: inline-block;
    margin-left: 4px;
    margin-bottom: 4px;
}

div.mustPanel span.tag-wrapper {
    display: inline-block;
    padding: 0px 7px;
    border-radius: $stdBorderRadius;
    border: 1px solid $selectionCardMIBG;
    margin-bottom: 4px;
    margin-top: 2px;
}

div.mustPanel div.ReactTags__tags > div {
    margin-bottom: -5px;
}

div.mustPanel span.tag-wrapper:hover {
    border: 1px solid $selectionCardMIHoverBorder;
}


div.mustPanel span.tag-wrapper button {
    padding: 0px 4px;
    border-radius: 15px;
    background: $selectionCardMIHoverBorder;
    border: none;
    line-height: 15px;
    margin: 8px 0 7px 0;
}

div.queryForm.minimized div.mustPanel span.tag-wrapper button {
    margin: 4px 0 3px 0;   
}

div.mustPanel span.tag-wrapper {
    background: $selectionCardMIBG;
    color: $selectionCardMIColor;
}

div.mustPanel div.selectionCard:hover {
    background: $selectionCardMIHoverBG;
    color: $selectionCardMIHoverColor;
}

div.queryForm span.kbHint {
    display: inline-block;
    margin-left: 25px;
    vertical-align: middle;
    display: none;
}

div.queryForm div.submitBtns svg {
    margin-right: 25px;
    vertical-align: middle;
}

div.queryForm div.submitBtns button {
    padding: 13px 21px;
}

div.QSQueries div.queryCard,
div.lastQueries div.queryCard {
    padding: 7px 15px;
    margin-left: -15px;
    margin-right: -15px;
    cursor: pointer;
    color: $suggestionColor;
    font-size: $fontSizeL;
    height: 35px;
}

div.QSQueries div.queryCard:hover,
div.lastQueries div.queryCard:hover {
    background: $linkLikeBtnActiveBG;
}

div.QSQueries div.queryCard span,
div.lastQueries div.queryCard span {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
    overflow: hidden; 
}

div.queryContainer div.quickEditPopup {
    position: absolute;
    z-index: 100;
}

div.queryForm button.showMoreBtn {
    float: right;
    margin: 0;
    width: auto;
    border-radius: $stdBorderRadius;
    margin-top: -4px;
    margin-right: -8px;
    outline: none;
    background: none;
}


div.queryForm div.taContainer textarea {
    background: transparent;
    z-index: 5;
    position: relative;
    display: block;
}


div.filtersPanel > div > button {
    margin-left: 0;
    margin-top: 2px;
    margin-bottom: 4px;
    padding: 6px 5px;
}

div.queryForm.minimized div.filtersPanel > div > button {
    padding: 2px 5px;
}


div.filterDropdown {
    display: inline-block;
}



div.filtersPanel h5 {
  font-size: $fontSizeS;
  margin-top: 10px;
  margin-bottom: 5px;
  color: $filtersPanelHeaderColor;
}

div.filtersPanel div#date-importance-container input {
  width: 100%;
}

div.filtersPanel div#closeness-container input {
  width: 100%;
}

div.filtersPanel ul li {
  list-style-type: none;
}

div.filtersPanel ul div.cbcontainerAll {
    border-bottom: 1px solid $panelBorderColor;
    margin-bottom: 6px;
    margin-left: -12px;
    margin-right: -12px;
    padding-right: 12px;
    padding-left: 12px;
    padding-bottom: 6px;
}


div.filtersPanel     ul li label {
  margin-left: 5px;
  text-transform: capitalize;
}

div.filtersPanel div.optionsMenu {
    max-height: calc(100vh - 270px);
    overflow-y: auto;
    min-width: 300px;
}

div.filtersPanel div.optionsMenu.doubleSize {
    min-width: 385px;
}

div.queryForm div#queryLang-container {
    position: absolute;
    z-index: 10;
}

div.queryForm div#queryLang-container div.optionsMenu {
    overflow: visible;
    width: 160px;
}

div.queryForm div#queryLang-container div.optionsMenu ul {
    overflow: hidden;
} 


div.queryForm div#queryLang-container h4 {
    margin: 13px 13px 0 13px;
}

div.queryForm div#queryLang-container > button {
    margin: 2px;
    width: 125px;
    padding-top: 4px;
    padding-bottom: 4px;
}

div.queryForm div.taContainer.withLanguage textarea {
    padding-left: 150px;
}