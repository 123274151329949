@import "_config.scss";


div.loginForm {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $panelBackground;
}

div.loginForm div.illustration {
    float: left;
    height: 100%;
    width: 30%;
}

div.loginForm div.contentsContainer {
    height: 100%;
    overflow: auto;
}

div.loginForm div.contents {
    padding: 70px 130px;
}

div.loginForm p.sub {
    color: $subColor;
    
}

div.loginForm input {
    width: 342px;
    max-width: 100%;
}




div.loginForm p.forgotPassword {
    display: block;
    width: 342px;
    text-align: right;
    max-width: 100%;
}

div.loginForm p.forgotPassword a {
    text-decoration: none;
}

div.loginForm form button {
    width: 342px;
    max-width: 100%;
    margin: 0;
    margin-top: 10px;
}


div.field + div.field {
    margin-top: 10px;
}

div.loginForm span.captionSpan {
    border-bottom: 1px solid $hSeparatorColor;
    display: block;
    margin-bottom: 30px;
}

div.loginForm span.captionSpan h3.caption {
    padding-top: 20px;
    padding-bottom: 20px;
    display: inline-block;
    border-bottom: $transparentBtnActiveThickBorder;
    margin-bottom: 0;
    margin-left: 21px;
}

div.loginForm span.captionSpan button.tabBtn + h3.caption {
    margin-left: 0;
}



@media only screen and (max-width: 1024px) {
    div.loginForm div.illustration {
        display: none;
    
    }

    div.loginForm div.contents {
        padding: 20px 30px;
    }
  }



